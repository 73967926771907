export default class FloatLabels {
	constructor(
		defaultSettings = {
			container:
				".form-field input, .form-field textarea, .woocommerce-form-row input, .form-row input, .form-row textarea",
		}
	) {
		this.defaultSettings = defaultSettings;
		this.init();
	}

	init() {
		this.getContainer.forEach((el) => {
			this.isAutoCompleted(el);
			el.addEventListener("focus", (e) => this.onFocus(e));
			el.addEventListener("keypress", (e) => this.onKeyPress(e));
			el.addEventListener("blur", (e) => this.onBlur(e));
		});
	}

	get getContainer() {
		return document.querySelectorAll(this.defaultSettings.container);
	}

	addActive(e) {
		e.target.closest("p").classList.add("active");
	}

	removeActive(e) {
		e.target.closest("p").classList.remove("active");
	}

	onFocus(e) {
		this.addActive(e);
	}

	onKeyPress(e) {
		if (e.target.value !== "") {
			this.addActive(e);
		}
	}

	onBlur(e) {
		if (e.target.value === "") {
			this.removeActive(e);
		}
	}

	isAutoCompleted(element) {
		const hasValue =
			element.getAttribute("value") && element.getAttribute("value").length > 0;
		if (hasValue) {
			element
				.closest(".form-field, .form-row, .woocommerce-form-row")
				.classList.add("active");
		}
	}
}

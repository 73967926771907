import Swiper from "swiper";
import SwiperCore, { Thumbs, Navigation } from "swiper/core";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Thumbs, Navigation]);

const productThumbnailSwiper = new Swiper(
	".products-thumbnails-container .swiper-container",
	{
		loop: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		slidesPerView: 2,
		breakpoints: {
			768: {
				slidesPerView: 5,
			},
		},
	}
);

const productSwiper = new Swiper(
	".woocommerce-product-gallery__wrapper.swiper-container",
	{
		loop: true,
		thumbs: {
			swiper: productThumbnailSwiper,
		},
	}
);

const buttonsRight = document.querySelectorAll(".swiper-button-next");
const buttonsLeft = document.querySelectorAll(".swiper-button-prev");
document
	.querySelectorAll(".ProductItem__image")
	.forEach((sliderContainer, index) => {
		const productLoopThumbsSlider = new Swiper(
			sliderContainer.querySelector(".ProductItem__sliderThumbs"),
			{
				slidesPerView: "auto",
			}
		);

		const productLoopSwiper = new Swiper(
			sliderContainer.querySelector(".ProductItem__slider"),
			{
				slidePerView: 1,
				navigation: {
					nextEl: buttonsRight[index],
					prevEl: buttonsLeft[index],
				},
				thumbs: {
					swiper: productLoopThumbsSlider,
				},
			}
		);
	});

document
	.querySelectorAll(".ProductItem__sliderAttribute")
	.forEach((button) =>
		button.addEventListener("click", (ev) => ev.preventDefault())
	);

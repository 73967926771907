const options = [
	{
		type: "Blue_Hawk",
		src: "produkt_bg_ukos_blue.jpeg",
		ilustration: "blue_hawk.png",
	},
	{
		type: "Pro_Gamer",
		src: "produkt_bg_ukos_pro-gamer.jpeg",
		ilustration: "pro-gamer_big.png",
	},
	{
		type: "Rookie",
		src: "produkt_bg_ukos_rookie.jpeg",
		ilustration: "rookie_big2.png",
	},
];

const HOST = `http://eyewear.games`;
const IMAGE_PATH = `${HOST}/wp-content/themes/starter/assets/images/`;

const lensInputs = document.querySelectorAll(
	'input[value="Blue_Hawk"],input[value="Pro_Gamer"],input[value="Rookie"]'
);
// const lensInputs = document.querySelectorAll('input[name="474_soczewki"]');
const lensBackground = document.querySelector(".Product__lensBackground");
const lensIlustration = document.querySelector(".Product__lensIlustration");

const changeBackground = (ev) => {
	const inputValue = ev.currentTarget.value;
	const optionType = options.filter((option) => option.type === inputValue);
	if (lensBackground) {
		lensBackground.style.backgroundImage = `url(${IMAGE_PATH}${optionType[0].src})`;
	}
	if (lensIlustration) {
		lensIlustration.setAttribute(
			"src",
			`${IMAGE_PATH}${optionType[0].ilustration}`
		);
	}

	if (optionType[0].type === "Rookie") {
		lensIlustration.classList.add("rookie-ilustration");
	}
};

lensInputs.forEach((lensInput) =>
	lensInput.addEventListener("change", changeBackground)
);

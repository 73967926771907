import $ from "jquery";
import { isMobile, handleMobileCategoryList } from "./helper";
import SiteHeader from "./siteHeader";
import FloatLabels from "./forms/float-labels";
import SearchForm from "./searchform";
import gridListSwitch from "./woocommerce/grid-list-switch";
import quantityButtonsHandler from "./woocommerce/quantity-buttons";
import addToCart from "./woocommerce/add-to-cart";
import faqContact from "./faq";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./lens-product-bg";
import "./try-on";
// import "./load-variation-gallery";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener("load", () => {
	new SiteHeader();
	new FloatLabels();

	const shopMenuContainer = document.querySelector(".shop-menu-container");
	if (shopMenuContainer) {
		document.body.addEventListener("show-cart-preview", () => {
			shopMenuContainer.classList.add("temp-open");
			setTimeout(() => {
				shopMenuContainer.classList.remove("temp-open");
			}, 3500);
		});
	}

	const searchFormContainer = document.querySelector(".search-form-container");
	if (searchFormContainer) {
		new SearchForm(searchFormContainer);
	}

	`	// if (document.querySelector(".ajax-add-to-cart")) {
	// 	addToCart();
	// }`;

	if (document.querySelector(".qty-wrapper")) {
		quantityButtonsHandler();
	}

	if (document.querySelector(".grid-list-selection")) {
		gridListSwitch();
	}

	if (document.querySelector(".faqs-container")) {
		new faqContact();
	}

	const mobileCategoriesListBtn = document.querySelector(
		".show-mobile-category-list"
	);
	if (mobileCategoriesListBtn) {
		handleMobileCategoryList(mobileCategoriesListBtn);
	}
});

const hamburger = document.querySelector(".Hamburger");
const navigation = document.querySelector(".Navigation");
const toggleMenu = () => {
	navigation.classList.toggle("Navigation--active");
	hamburger.classList.toggle("Hamburger--open");
};
hamburger && hamburger.addEventListener("click", toggleMenu);

gsap.fromTo(
	".Hero__image",
	{ xPercent: 20, opacity: 0 },
	{ xPercent: 0, opacity: 1, duration: 1 }
);

gsap.fromTo(
	".Hero__title",
	{ xPercent: 20, opacity: 0 },
	{ xPercent: 0, opacity: 1, duration: 1 }
);

gsap.fromTo(
	".Hero__subtitle",
	{ xPercent: 20, opacity: 0 },
	{ xPercent: 0, opacity: 1, duration: 1 }
);

gsap
	.timeline({
		scrollTrigger: {
			trigger: ".FrontpageSection__images",
		},
	})
	.to(".FrontpageSection__images", {
		opacity: 1,
		duration: 1.2,
	});

gsap
	.timeline({
		scrollTrigger: {
			trigger: ".FrontpageSection__image",
		},
	})
	.to(".FrontpageSection__image", {
		opacity: 1,
		duration: 1.2,
	});

ScrollTrigger.matchMedia({
	"(min-width: 1024px)": () => {
		const parallaxImages = document.querySelectorAll(
			".FrontpageSection--parallax .FrontpageSection__image, .FrontpageSection--parallax .FrontpageSection__images, #frontpage-parallax-girl"
		);
		parallaxImages.forEach((parallaxImage) => {
			gsap.to(parallaxImage, {
				yPercent: -35,
				ease: "none",
				scrollTrigger: {
					trigger: parallaxImage,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
				},
			});
		});
	},
});

const sizesPopup = document.querySelector(".SizesPopup");

if (sizesPopup) {
	const ACTIVE_CLASS = "SizesPopup--active";

	const togglePopup = () => {
		sizesPopup.classList.toggle(ACTIVE_CLASS);
	};

	const togglePopupWithPropagation = (e) => {
		e.stopPropagation();
		togglePopup();
	};

	const closeSizesPopupBtn = document.querySelector(".SizesPopup__close");
	const openSizesPopupBtn = document.querySelector(".Product__sizesBtn");

	document.body.addEventListener("click", () => {
		if (sizesPopup.classList.contains(ACTIVE_CLASS)) {
			togglePopup();
		}
	});

	sizesPopup.addEventListener("click", (e) => {
		e.stopPropagation();
	});

	closeSizesPopupBtn.addEventListener("click", togglePopupWithPropagation);

	openSizesPopupBtn.addEventListener("click", togglePopupWithPropagation);
}

$(".ProductLensSection__goBackBtn").on("click", function() {
	$("html, body").animate({ scrollTop: "0" });
});

$(window).on("load", function() {
	var LoadVideo = function(player_id) {
		var Program = {
			Init: function() {
				if (!$("#" + player_id).length) return;

				this.NewPlayer();
				this.EventHandler();
			},

			NewPlayer: function() {
				var _this = this;
				this.Player = new YT.Player(player_id, {});
				_this.Player.$element = $("#" + player_id);
			},

			Play: function() {
				if (this.Player.getPlayerState() === 1) return;
				this.Player.playVideo();
			},

			Pause: function() {
				if (this.Player.getPlayerState() === 2) return;
				this.Player.pauseVideo();
			},

			ScrollControl: function() {
				if (Utils.IsElementInViewport(this.Player.$element[0])) this.Play();
				else this.Pause();
			},

			EventHandler: function() {
				var _this = this;
				$(window).on("load scroll", function() {
					_this.ScrollControl();
				});
			},
		};
		var Utils = {
			/** @author http://stackoverflow.com/a/7557433/1684970 */
			IsElementInViewport: function(el) {
				if (typeof jQuery === "function" && el instanceof jQuery) el = el[0];
				var rect = el.getBoundingClientRect();
				return (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <=
						(window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <=
						(window.innerWidth || document.documentElement.clientWidth)
				);
			},
		};
		return Program.Init();
	};
	LoadVideo("video");
});

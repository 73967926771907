import $ from "jquery";

let fitmixInstance;

const params = {
	apiKey: "J2pTiZfigiEqTfbBvrnEU0H98cyCJ38HYF2wBqc1", // replace by your apiKey (see Requirements)
};

let isLoaded = false;
let fitmixIsVisible = false;
let selectedGlasses;

const setGlassIDFromCurrentVariation = () => {
	const variations = $(".variations_form").data("product_variations");

	if (!variations) return;

	setTimeout(() => {
		const currentVariation = variations.find(
			(variation) => variation.variation_id == $("input.variation_id").val()
		);

		$("input.glass_id").val(currentVariation.glass_id);
		setSelectedGlasses(currentVariation.glass_id);
	}, 1000);
};

$(".variations_form").on(
	"woocommerce_variation_select_change",
	setGlassIDFromCurrentVariation
);

// create the fitmixInstance, this must be called only once
const loadFitMix = () => {
	if (isLoaded) return;
	isLoaded = true;
	fitmixInstance = FitMix.createWidget("fitmix-container", params);
};

// hide fitmix container and related buttons
const hideFitMix = () => {
	document.querySelector(".Product__tryOn").style.display = "none";
	document.querySelector(".Product__tryOnButtonClose").style.display = "none";
	document.querySelector(".Product__tryOnButton").style.display = "block";
	document.querySelector(".Product__goToFitting").style.display = "block";

	fitmixInstance.pauseStream();
	fitmixIsVisible = false;
};

// show fitmix container and related buttons
const showFitMix = () => {
	document.querySelector(".Product__tryOn").style.display = "block";
	document.querySelector(".Product__tryOnButton").style.display = "none";
	document.querySelector(".Product__goToFitting").style.display = "none";
	document.querySelector(".Product__tryOnButtonClose").style.display = "block";
	fitmixInstance.resumeStream();
	fitmixIsVisible = true;
};

const setSelectedGlasses = (sku) => {
	selectedGlasses = sku;

	if (fitmixIsVisible) {
		fitmixInstance.setFrame(selectedGlasses);
	}
};

// wait the page to be ready for initilization
window.onload = () => {
	setGlassIDFromCurrentVariation();

	document.querySelector(".Product__tryOnButton") &&
		document
			.querySelector(".Product__tryOnButton")
			.addEventListener("click", () => {
				loadFitMix();

				setTimeout(() => {
					setSelectedGlasses(selectedGlasses);
				}, 1000);

				if (!fitmixIsVisible) {
					showFitMix();
				}
			});

	document.querySelector(".Product__tryOnButtonClose") &&
		document
			.querySelector(".Product__tryOnButtonClose")
			.addEventListener("click", () => {
				if (fitmixIsVisible) {
					hideFitMix();
				}
			});
};
